<template>
    <OModal :name="modalName" ref="ref_OModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="readOnly" class="modal-title">{{ $t(modalTitle) }}</h5>
                    <h5 v-else class="modal-title">{{ $t(modalTitleEdit ? modalTitleEdit : 'Edit ' + modalTitle) }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex flex-column" style="height:500px; overflow: none;">
                        <textarea 
                            class="flex-grow-1 flex-shrink-1 form-control"
                            :value="modelValue"
                            @change="$emit('update:modelValue', $event.target.value)"
                            :readonly="readOnly"
                            :disabled="readOnly">
                        </textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import { ref } from "vue";

    defineProps(['modalName', 'modalTitle', 'modalTitleEdit', 'modelValue', 'readOnly']);
    const emit = defineEmits(['update:modelValue']);

    const ref_OModal = ref(null);

///usage
// import ModalEditField from 'contract.vue.components.ModalEditField.vue';

// <ModalEditField 
        // modalName = "modalEditDescription"
        // modalTitleEdit = "Edit Description" //edit mode title, prop can be omitted
        // modalTitle = "Description" //regular title
        // v-model = "dsBOQ.current.Description"
        // :readOnly = "!dsBOQ.allowUpdate"/>
    // <ModalEditField 
        // modalName = "modalEditComments"
        // modalTitleEdit = "Edit Comments"
        // modalTitle = "Comments"
        // v-model = "dsBOQ.current.Comments"
        // :readOnly = "!dsBOQ.allowUpdate"/>

</script>   
